import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

import Section from "./section"

import { SOCIALLINKS } from "../constants/information"

const Contact = () => {
  return (
    <div id="contact">
      <h5 className="title-section">Contactame</h5>
      <section className="section-container ">
        <Section />
        <article className="section__content section__content-spacing sesstion-container-justify-no">
          <div className="default-container contact">
            <div className="layout-contact">
              <div className="container-form">
                <form action="https://formspree.io/f/mbjqlggn" method="POST">
                  <div>
                    <label
                      className="custom-field"
                      data-sal="slide-right"
                      data-sal-delay="300"
                      data-sal-easing="ease"
                    >
                      <input type="text" required name="name" />
                      <span className="placeholder">Nombre</span>
                    </label>

                    <label
                      className="custom-field"
                      data-sal="slide-right"
                      data-sal-delay="300"
                      data-sal-easing="ease"
                    >
                      <input type="email" required name="_replyto" />
                      <span className="placeholder">Email</span>
                    </label>
                    <label
                      className="custom-field"
                      data-sal="slide-right"
                      data-sal-delay="300"
                      data-sal-easing="ease"
                    >
                      <input
                        type="text"
                        required
                        className="full-content"
                        name="subject"
                      />
                      <span className="placeholder">Asunto</span>
                    </label>
                  </div>
                  <div
                    className="custom-field"
                    data-sal="slide-right"
                    data-sal-delay="300"
                    data-sal-easing="ease"
                  >
                    <textarea
                      className="full-content"
                      required
                      name="message"
                    ></textarea>
                    <span className="placeholder-textarea">Mensaje</span>
                  </div>
                  <button
                    type="submit"
                    className="btn btn__primary "
                    data-sal="slide-right"
                    data-sal-delay="300"
                    data-sal-easing="ease"
                  >
                    Enviar
                  </button>
                </form>
              </div>

              <div className="container-social-media">
                {SOCIALLINKS.map((element, id) => (
                  <a
                    href={element.url}
                    key={id}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="btn btn__primary btn__text-l button__contact"
                  >
                    <span>
                      <FontAwesomeIcon icon={element.icon} />
                    </span>
                    <span>{element.name}</span>
                  </a>
                ))}
              </div>
            </div>
          </div>
        </article>
      </section>
    </div>
  )
}

export default Contact
