import React, { useContext } from "react"
import { useStaticQuery, graphql } from "gatsby"

import Section from "./section"
// import Image from "./image"
import Icons from "./icons"
import Type from "./type"

import GetImage from "./GetImage"

import { GlobalStateContect } from "../context/GlobalContextProvider"

const Hero = () => {
  const state = useContext(GlobalStateContect)

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
          description
        }
      }
    }
  `)

  return (
    <>
      <section id="home" className="section-container section-container-hero">
        <Section image={true} />
        <article className="section__content article__content">
          <div className="content__hero">
            <span className="floating-text">
              hola mi nombre es
              {state?.theme === "dark" ? (
                <GetImage src="arrow/arrow-1.webp" />
              ) : (
                <GetImage src="arrow/arrow-1-light.webp" />
              )}
            </span>
            <h1
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              {data?.site?.siteMetadata?.title}
            </h1>

            <div className="info">
              <Type
                descriptions={[
                  "estudiante",
                  "autodidacta",
                  "Desarrollador <strong>web.<strong/>",
                  "jr developer",
                ]}
                initialText="soy"
              />
              <p
                className="text-info"
                data-sal="zoom-in"
                data-sal-delay="100"
                data-sal-easing="ease"
              >
                {data.site.siteMetadata?.description}
              </p>
              <Icons hover={true} />
            </div>
          </div>
          {state?.theme === "dark" ? (
            <GetImage src="avatar/avatar-dark.webp" />
          ) : (
            <GetImage src="avatar/avatar-light.webp" />
          )}
        </article>
      </section>
    </>
  )
}

export default Hero
