import React, { useEffect, useRef, memo } from "react"
import Typed from "typed.js"

const Type = ({ descriptions, initialText }) => {
  const el = useRef(null)
  useEffect(() => {
    const options = {
      strings: descriptions,
      typeSpeed: 50,
      startDelay: 1200,
      backSpeed: 50,
      backDelay: 1500,
      loop: true,
    }
    new Typed(el.current, options)
  }, [descriptions])

  return (
    <>
      <p className="typed-container">
        {initialText} <span ref={el} />
      </p>
    </>
  )
}

export default memo(Type, (prevProps, nextProps) => {
  return true
})
