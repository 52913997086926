import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faGithub } from "@fortawesome/free-brands-svg-icons"
import { faLink } from "@fortawesome/free-solid-svg-icons"

import Section from "./section"

import { PORTFOLIO } from "../constants/information"

const Portfolio = () => {
  return (
    <div id="portfolio">
      <h5 className="title-section">Portafolio</h5>
      <section className="section-container ">
        <Section />
        <article className="section__content section__content-spacing sesstion-container-justify-no">
          <div className="default-container  portfolio">
            <p
              className="text portfolio-info"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              Estos son algunos de los proyectos que he realizado.
            </p>
            <div className="portfolio__container">
              {PORTFOLIO.map((element, id) => (
                <div
                  className="card"
                  key={id}
                  data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  <div
                    className={`card__content-image  ${
                      id % 2 !== 0 ? "card__content-image-right " : ""
                    }`}
                    style={{
                      backgroundImage: `url(${element.image})`,
                    }}
                  />

                  <div
                    className={`card__contet-info ${
                      id % 2 !== 0 ? "card__content-info-right" : ""
                    }`}
                  >
                    <a
                      href={element.urlLink}
                      rel="noopener noreferrer"
                      className="titlte-link"
                      target="_blank"
                    >
                      <h2 className="suptitle">{element.title}</h2>
                    </a>
                    <p className="text">{element.description}</p>
                    <div
                      className={`card__content-tics ${
                        id % 2 !== 0 ? "card__content-tics-right" : ""
                      }`}
                    >
                      {element.technologies.map((tics, idTics) => (
                        <span className="tics" key={idTics}>
                          {tics}
                          {element.technologies.length === idTics + 1
                            ? ""
                            : ","}
                        </span>
                      ))}
                    </div>
                    <div className="card__content-links">
                      {element.github ? (
                        <a
                          href={element.urlGitHub}
                          rel="noopener noreferrer"
                          className="btn btn__icon btn__icon-theme link"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faGithub} />
                        </a>
                      ) : (
                        ""
                      )}

                      {element.link ? (
                        <a
                          href={element.urlLink}
                          rel="noopener noreferrer"
                          className="btn btn__icon btn__icon-theme link"
                          target="_blank"
                        >
                          <FontAwesomeIcon icon={faLink} />
                        </a>
                      ) : (
                        ""
                      )}
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </article>
      </section>
    </div>
  )
}

export default Portfolio
