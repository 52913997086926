import React, { useContext } from "react"

import Section from "./section"
import GetImage from "./GetImage"

import { GlobalStateContect } from "../context/GlobalContextProvider"

const SoftSkills = () => {
  const state = useContext(GlobalStateContect)
  return (
    <div id="softSkills">
      <h5 className="title-section">Habilidades blandas</h5>
      <section className="section-container ">
        <Section />
        <article className="section__content section__content-spacing softSkills">
          <div className="softSkills__list">
            <ul>
              <li>
                <p
                  className="text"
                  data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  Soy bueno trabajando en equipo, ya que me gusta cuando tengo
                  compañeros con los cuales debatir las mejores opciones para
                  que nuestro trabajo sea excelente.
                </p>
              </li>
              <li>
                <p
                  className="text"
                  data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  Soy una persona proactiva y participativa en los proyectos.
                </p>
              </li>
              <li>
                <p
                  className="text"
                  data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  Siempre me gusta estar dispuesto a tener nuevos conocimientos,
                  ya que asi puedo mejorar mi rendimineto constantemente.
                </p>
              </li>
              <li>
                <p
                  className="text"
                  data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  La programación más que una profesión, es un hobby para mí, me
                  encanta estar investigando nuevas tecnologias y lenguajes que
                  surgen en el mercado.
                </p>
              </li>
              <li>
                <p
                  className="text"
                  data-sal="slide-right"
                  data-sal-delay="300"
                  data-sal-easing="ease"
                >
                  Me destaco por tener una buena atencion al detalle y estetica
                  en cada uno de los proyecto que realizo.
                </p>
              </li>
            </ul>
          </div>
          <div
            className="content__photo"
            data-sal="zoom-in"
            data-sal-delay="50"
            data-sal-easing="ease"
          >
            <GetImage src="photo.webp" />
            <span className="floating-text-about">
              este soy yo
              {state?.theme === "dark" ? (
                <GetImage src="arrow/arrow-2.webp" />
              ) : (
                <GetImage src="arrow/arrow-2-light.webp" />
              )}
            </span>
          </div>
        </article>
      </section>
    </div>
  )
}

export default SoftSkills
