import React from "react"

import Section from "./section"
import Skills from "./skills"

import file from "../static/JuanPabloAgudelo(cv).pdf"

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faFilePdf } from "@fortawesome/free-solid-svg-icons"

const About = () => {
  return (
    <div id="about">
      <h5 className="title-section">Sobre mi</h5>
      <section className="section-container ">
        <Section />
        <article className="section__content section__content-spacing about">
          <div className="content-about">
            <div className="container-p">
              <p
                className="text-info"
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                Nací en un hermoso pueblo llamado Rionegro en el departamento de
                Antioquia Colombia, tengo
                {" " + String(new Date().getFullYear()).substr(2, 3)} años. Una
                de mis pasiones es el desarrollo web, ya que disfruto
                construyendo interfaces de usuario que sean adaptables para los
                dispositivos, tambien me gusta generar soluciones a los
                problemas que puedan presentar las aplicaciones.
              </p>
              <h6
                className="suptitle"
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                Front-end Developer.
              </h6>
              <p
                className="text-info"
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                Realice mis estudios en la carrera ADSI (Análisis y desarrollo
                de sistemas de información) en el Servicio Nacional de
                Aprendizaje{" "}
                <a
                  className="External-link"
                  href="https://www.sena.edu.co/es-co/Paginas/default.aspx"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Sena.
                </a>{" "}
              </p>
              <p
                className="text-info"
                data-sal="slide-right"
                data-sal-delay="300"
                data-sal-easing="ease"
              >
                Soy autodidacta aprendiendo tecnologías nuevas día tras día en
                plataformas como{" "}
                <a
                  className="External-link"
                  href="https://udemy.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Udemy
                </a>{" "}
                o{" "}
                <a
                  className="External-link"
                  href="https://platzi.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Platzi
                </a>{" "}
                con relación a desarrollo front-end y back-end.
              </p>
            </div>
            <br />
            <a
              className="btn btn__primary btn__resume"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
              href={file}
              download
            >
              <FontAwesomeIcon icon={faFilePdf} />
              <span>Resumen (CV)</span>
            </a>
          </div>

          <div className="about__skills">
            <h3
              className="skills__title suptitle"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              habilidades.
            </h3>
            <p
              className="text-info"
              data-sal="slide-right"
              data-sal-delay="300"
              data-sal-easing="ease"
            >
              Estas son algunas de las habilidades que tengo para el desarrollo
              web con las siguientes tecnologías y frameworks con las que
              trabajo habitualmente.
            </p>
            <div className="skills__container">
              <Skills />
            </div>
          </div>
        </article>
      </section>
    </div>
  )
}

export default About
