import React from "react"

import SEO from "../components/seo"
import Hero from "../components/hero"
import About from "../components/about"
import Softskills from "../components/softSkills"
import Contact from "../components/contact"
import Portfolio from "../components/portfolio"

const IndexPage = () => {
  return (
    <>
      <SEO title="portfolio" />
      <Hero />
      <About />
      <Softskills />
      <Portfolio />
      <Contact />
    </>
  )
}

export default IndexPage
