import React, { useContext } from "react"
import GetImage from "./GetImage"
import { GlobalStateContect } from "../context/GlobalContextProvider"

const Section = ({ image = false }) => {
  const state = useContext(GlobalStateContect)

  return (
    <div className="section-left">
      {image !== false ? (
        state?.theme === "dark" ? (
          <GetImage src="logo/logo.webp" />
        ) : (
          <GetImage src="logo/logo-light.webp" />
        )
      ) : (
        ""
      )}
      <div className="line" />
    </div>
  )
}

export default Section
