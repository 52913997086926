import React from "react"

import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"

const Skills = () => {
  const data = useStaticQuery(graphql`
    query Skills {
      skills: allFile(
        sort: { fields: base, order: ASC }
        filter: {
          relativeDirectory: { eq: "skills" }
          extension: { regex: "/(webp)/" }
        }
      ) {
        edges {
          node {
            name
            childImageSharp {
              fluid {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      }
    }
  `)

  return (
    <>
      {data.skills.edges.map((item, i) => (
        <div
          className="skill"
          key={i}
          data-sal="zoom-in"
          data-sal-delay="500"
          data-sal-easing="ease"
        >
          <Img fluid={item.node.childImageSharp.fluid} />
          <p>{item.node.name}</p>
        </div>
      ))}
    </>
  )
}

export default Skills
